/**
 * Created by giridhar on 20/9/17.
 */

import { RType, Result } from './result';
import { CommandParser } from './notification_client';
import { ParserException } from './error';

export class SocialCommandParser implements CommandParser {
  private actParse(data: string, idData: string): Result {
    let arr: string[] = idData.split(' ', 2);
    let id: number = parseInt(arr[0], 10);
    let type: RType =
      arr[1].startsWith('OK ') || arr[1] === 'OK'
        ? RType.SUCCESS
        : RType.FAILURE;
    return new Result(id, type, data);
  }

  private _parse(msg: string): Result {
    let last: number = msg.length;
    if (msg.charAt(last - 1) != '\n' || msg.charAt(last - 2) != '\r') {
      throw new ParserException('msg not ended with CRLF ' + msg);
    }

    msg = msg.substring(0, last - 2);

    let i: number = msg.lastIndexOf('\r');
    //if didn't find \r set i to 0
    let tagStart;
    if (i == -1) {
      i = tagStart = 0;
    } else {
      //removing \r\n from tag
      tagStart = i + 2;
    }
    let data: string = msg.substring(0, i);
    return this.actParse(data, msg.substring(tagStart));
  }

  public parse(msg: string): Result {
    try {
      return this._parse(msg);
    } catch (err) {
      if (err instanceof ParserException) {
        throw err;
      } else {
        throw new ParserException(
          'Exception while trying to parse ' + msg + err
        );
      }
    }
  }
}
