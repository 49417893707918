import { PubSubNotificationClient } from "../core/notification/pub_sub_client";
import { Result } from "../core/notification/result";
import { ServiceType } from "../core/notification/service_type";
import { ZMNotification } from "../core/notification/zm_notification";
import { SessionService } from "./session.service";

export abstract class EventStreamAdaptar {

  private subLatchMap: Map<string, number>;

  constructor() {
    this.subLatchMap = new Map();
  }

  public static getInstance(): EventStreamAdaptar {
    if (window.SharedWorker !== undefined) return SharedEventStreamAdaptar.INSTANCE;
    else if (window.Worker !== undefined) return WorkerEventStreamAdaptar.INSTANCE;
    else return WSEventStreamAdaptar.INSTANCE;
  }

  private getKey(sType: ServiceType, service: string): string {
    return sType.getName() + '.' + service;
  }

  private intLatch(sType: ServiceType, service: string) {
    let key = this.getKey(sType, service);
    let c = this.subLatchMap.has(key) ? this.subLatchMap.get(key) : 0;
    this.subLatchMap.set(key, c + 1);
  }

  private decLatch(sType: ServiceType, service: string): number {
    let key = this.getKey(sType, service);
    let c = this.subLatchMap.has(key) ? this.subLatchMap.get(key) : 0;
    c = c - 1;

    if(c == 0) this.subLatchMap.delete(key);
    else this.subLatchMap.set(key, c);

    return c;
  }

  subscribe(sType: ServiceType, callback: (resp: Result) => void, service: string): Subscription {
    let sub = this._subscribe(sType, callback, service);

    this.intLatch(sType, service);
    return new SubscriptionImpl(() => {
      /*
      When moving from Display to dashboard, sometimes Display unsubscribe happens before and then dashboard subscribe
      and on the network layer subscribe happes before we get response to the unscribe. Since we use the same service id
      it's better to unsubscribe with an delay
      */
      setTimeout(() => {
        let c = this.decLatch(sType, service);
        if (c == 0) sub.close(); // do actual close
        else sub.softClose(); // there is new reference, soft close
      }, 400);
    })
  }

  protected abstract _subscribe(sType: ServiceType, callback: (resp: Result) => void, service: string): SubscriptionImpl;

  abstract resource(serviceType: ServiceType, callback: (resp: Result) => void, resource: string): void;

  abstract setConnListener(cb: (connected: boolean) => void): void;

  abstract terminate(): void;

}

abstract class AbstractWorker extends EventStreamAdaptar {

  private static seqNo: number = 1;
  private connCB: ((connected: boolean) => void)[];
  private cbMap: Map<number, (resp: Result) => void>;

  constructor() {
    super();
    this.connCB = [];
    this.cbMap = new Map();
  }

  private genSeqNo(): number {
    return AbstractWorker.seqNo++;
  }

  _subscribe(sType: ServiceType, callback: (resp: Result) => void, service: string): SubscriptionImpl {
    this.init();

    let seq = this.genSeqNo();
    this.cbMap.set(seq, callback);

    this.getChannel().postMessage({ ev: 'sub', sType: sType.getName(), subscribe: true, service: service, 'seq': seq });

    return new SubscriptionImpl((softClose: boolean) => {

      this.cbMap.delete(seq); // remove sub reference
      if(softClose) return; // don't do network close. Clear any reference

      this.getChannel().postMessage({
        ev: 'sub', sType: sType.getName(), subscribe: false, service: service,
        'seq': this.genSeqNo()
      });
    })
  }

  resource(sType: ServiceType, callback: (resp: Result) => void, resource: string): void {
    this.init();

    let seq = this.genSeqNo();
    this.cbMap.set(seq, callback);

    this.getChannel().postMessage({ ev: 'res', sType: sType.getName(), resource: resource, 'seq': seq });
  }

  setConnListener(cb: (connected: boolean) => void): void {
    this.init();
    this.connCB.push(cb);
  }

  onConnectionState(connected: boolean) {
    this.init();
    this.connCB.forEach(cb => cb(connected));
  }

  private _onWrokerMessage(data: string) {
    if (data.startsWith('CMD ')) {
      let cmd = data.substring(4);

      if (cmd === 'PING') {
        this.getChannel().postMessage('PONG');
      } else if (cmd == 'RECONNECT' || cmd == 'DISCONNECT') {
        this.onConnectionState(cmd == 'RECONNECT');
      }
    } else {
      let s = data.indexOf(' ');
      let seq = parseInt(data.substring(0, s));
      let raw = data.substring(s + 1);
      let res: Result = JSON.parse(raw);
      let f = this.cbMap.get(seq);
      if(f) f(res);
    }
  }

  onWrokerMessage(data: string) {
    try {
      this._onWrokerMessage(data);
    } catch (err) {
      console.error('Eception while processing ' + data, err);
    }
  }

  abstract init(): void;
  abstract getChannel(): CommonWorkerChannel;

}

class SharedEventStreamAdaptar extends AbstractWorker {

  static readonly INSTANCE: SharedEventStreamAdaptar = new SharedEventStreamAdaptar();

  private inited: boolean = false;

  private sw: SharedWorker = null;


  init(): void {
    if (this.inited) return;

    this.sw = new SharedWorker(new URL('./web-worker/event-stream.worker.worker', import.meta.url),
      { type: 'module', name: 'event-stream.worker' })

    this.sw.onerror = (ev) => console.error(ev);
    this.sw.port.postMessage('session_id ' + SessionService.getSessionId());

    this.sw.port.onmessage = (ev) => {
      let resp: string = ev.data;
      this.onWrokerMessage(resp);
    }

    this.sw.port.start();
    this.inited = true;
  }

  getChannel(): CommonWorkerChannel {
    return this.sw.port;
  }

  terminate(): void {
    this.sw?.port?.postMessage('EXIT');
    this.sw = null;
    this.inited = false;
  }

}

class WorkerEventStreamAdaptar extends AbstractWorker {

  static readonly INSTANCE: WorkerEventStreamAdaptar = new WorkerEventStreamAdaptar();

  private inited: boolean = false;

  private w: Worker = null;


  init(): void {
    if (this.inited) return;

    this.w = new Worker(new URL('./web-worker/event-stream.worker.worker', import.meta.url),
      { type: 'module', name: 'event-stream.worker' })

    this.w.onerror = (ev) => console.error(ev);
    this.w.postMessage('session_id ' + SessionService.getSessionId());

    this.w.onmessage = (ev) => {
      let resp: string = ev.data;
      this.onWrokerMessage(resp);
    }

    this.inited = true;
  }

  getChannel(): CommonWorkerChannel {
    return this.w;
  }

  terminate(): void {
    this.w.terminate();
    this.w = null;
    this.inited = false;
  }

}

export interface Subscription {
  close(): void;
}

class SubscriptionImpl implements Subscription {

  constructor(private onClose: (softClose: boolean) => void) { }

  close() {
    this.onClose(false);
  }

  softClose() {
    this.onClose(true);
  }
}

interface CommonWorkerChannel {
  postMessage(message: any, transfer: Transferable[]): void;
  postMessage(message: any, options?: StructuredSerializeOptions): void;
}

class WSEventStreamAdaptar extends EventStreamAdaptar {

  static readonly INSTANCE: WSEventStreamAdaptar = new WSEventStreamAdaptar();

  private inited: boolean = false;
  private psn: PubSubNotificationClient;
  private firstConnectEvent: boolean = true;

  private getEventURL(): string {
    let host = window.location.host;
    let schema = window.location.protocol === 'http:' ? 'ws:' : 'wss:';

    return schema + '//' + host + '/event?session=' + SessionService.getSessionId();
  }

  private init() {
    if (this.inited) return;

    this.psn = new PubSubNotificationClient(this.getEventURL());
    this.inited = true;
  }

  _subscribe(sType: ServiceType, callback: (resp: Result) => void, service: string): SubscriptionImpl {
    this.init();

    this.psn.subscribe(sType, {
      onReply(resp) {
        callback(resp);
      }
    }, service, ZMNotification.DUMMY_AFTER);

    return new SubscriptionImpl((softClose: boolean) => {
      if(softClose) return;

      this.psn.unSubscribe(sType, { onReply() { } }, service);
    })
  }

  resource(sType: ServiceType, callback: (resp: Result) => void, resource: string): void {
    this.init();

    this.psn.resource(sType, {
      onReply(resp) {
        callback(resp);
      }
    }, resource);
  }

  setConnListener(cb: (connected: boolean) => void): void {
    this.init();
    this.psn.setConnListener((c) => {
      if (this.firstConnectEvent && c) this.firstConnectEvent = false;
      else cb(c);
    });
  }


  terminate(): void {
    this.psn?.stop();
    this.psn = null;
    this.inited = false;
    this.firstConnectEvent = true;
  }

}