/**
 * Created by giridhar on 2/11/16.
 */

export class ServiceType {

    private readonly name: string;

    private constructor(name:string) {
        this.name = name;
    }

    public getName(): string {
        return this.name;
    }

    public static getByName(name: string): ServiceType {
        switch(name) {
            case 'C_SYNC': return ServiceType.C_SYNC;
            case 'DAO_CHANGE': return ServiceType.DAO_CHANGE;
        }
        return null;
    }

    public static readonly C_SYNC = new ServiceType('C_SYNC');
    public static readonly DAO_CHANGE = new ServiceType('DAO_CHANGE')
}
