/**
 * Created by giridhar on 2/11/16.
 */

export enum RType {
    SUCCESS,
    FAILURE
}

export class Result {
    readonly id: number;
    readonly type: RType;
    readonly resp: string;

    public constructor(id: number, type: RType, resp: string) {
        this.id = id;
        this.type = type;
        this.resp = resp;
    }

    public toString(): string {
        return "Result{" +
            "id=" + this.id +
            ", type=" + this.type +
            ", resp='" + this.resp + '\'' +
            '}';
    }
}
