/**
 * Created by giridhar on 20/9/17.
 */

import { Command, CommandType } from './command';
import { After } from './zm_notification';
import { ServiceType } from './service_type';
import { Util } from './util';

let CRLF: string = '\r\n';

export abstract class PubSubCommand extends Command {
  public static readonly SUBSCRIBE = new CommandType('SUBSCRIBE', true);
  public static readonly UNSUBSCRIBE = new CommandType('UNSUBSCRIBE', false);
  public static readonly RESOURCE = new CommandType('RESOURCE', false);

  private readonly service: string;

  constructor(
    id: number,
    cmdType: CommandType,
    serviceType: ServiceType,
    service: string,
    ...args: any[]
  ) {
    super(id, cmdType, serviceType, service, args);
    this.service = service;
  }

  public createCommand(nextId: number, cmdType: CommandType,
    serviceType: ServiceType, ...args: string[]): string {

    args = Util.getValue(args, []);
    return `${nextId} ${cmdType.getName()} ${serviceType.getName()} ${args.join(' ').trim()}${CRLF}`;
  }

  public getService(): string {
    return this.service;
  }
}

export class SubCommand extends PubSubCommand {
  private readonly after: After;

  constructor(
    id: number,
    serviceType: ServiceType,
    service: string,
    after: After
  ) {
    super(id, PubSubCommand.SUBSCRIBE, serviceType, service, after.after());
    this.after = after;
  }

  public getCommand(): string {
    return this.createCommand(
      this.getId(),
      this.getType(),
      this.getServiceType(),
      this.getService(),
      this.after.after()
    );
  }

  public getAfter(): After {
    return this.after;
  }
}

export class UnSubCommand extends PubSubCommand {
  constructor(id: number, serviceType: ServiceType, service: string) {
    super(id, PubSubCommand.UNSUBSCRIBE, serviceType, service);
  }
}

export class ResourceCommand extends PubSubCommand {
  constructor(id: number, serviceType: ServiceType, resource: string) {
    super(id, PubSubCommand.RESOURCE, serviceType, resource);
  }
}
