/**
 * Created by giridhar on 3/11/16.
 */

import { Command } from './command';
import { ServiceType } from './service_type';
import { ZMWCallback } from './zm_notification';
import { Result, RType } from './result';
import { Listener, WebSocketClient } from './ws_client';
import { Util } from './util';

let NOOP: string = 'NOOP\r\n';

export abstract class NotificationClient {
  private static ID_GEN: number = 1;

  private static readonly TAG: string =
    'app.notification.notification_client.NotificationClient';

  public static readonly NOOP: string = 'NOOP\r\n';
  private static readonly MAX_INACTIVE_TIME = 60000; // 60s

  protected readonly cmdCallbackMap: Map<number, CommandCallHolder>;

  private canSend: boolean;
  private noopSchedule: any;
  private scheduledRetry: boolean;
  private client: WebSocketClient;

  private firstTime: boolean;
  private lastActivity!: number; //Last packet received from server
  private stopped: boolean;
  private readonly wsUrl: string;

  constructor(wsURL: string) {
    this.wsUrl = wsURL;
    this.cmdCallbackMap = new Map<number, CommandCallHolder>();
    this.canSend = false;
    this.scheduledRetry = false;
    this.client = new WebSocketClient(this.wsUrl);
    this.noopSchedule = setInterval(() => this.writeNoop(), 15000);
    this.firstTime = true;
    this.stopped = false;
  }

  stop(): void {
    if (this.client === null) return;

    //event if we are trying to reconnect, have to clear the old backlog contents
    this.client.disconnect();
    this.cmdCallbackMap.clear();
    clearInterval(this.noopSchedule);
    this.noopSchedule = null;
    this.canSend = false;
    this.firstTime = true;
    this.stopped = true;
  }

  private initClient(): void {
    if(this.stopped) throw new Error("Notification Client stopped");
    /*
         connect happens asynchronously, Even if we get the client instance it doesn't mean
         we could write data on wire, use ZMWSListener's onConnected method
         */
    this.client.connect(new ZMWSListener(this, this.createCMDParser()));
  }

  protected abstract createCMDParser(): CommandParser;

  private writeNoop(): void {
    if (!this.canSend) return;

    if (this.lastActivity + NotificationClient.MAX_INACTIVE_TIME > Date.now()) {
      this.client.send(NotificationClient.NOOP);
    } else {
      //  Logger.e(NotificationClient.TAG, "writeNoop", "ReadTimeout last activity - "+this.lastActivity);
      this.client.disconnect();
      this.clearResourceAndReschedule();
    }
  }

  protected writeCommand(cch: CommandCallHolder): void {
    if (this.firstTime) { //if first time; will write after connection created
      this.initClient();
      this.firstTime = false;
      return;
    }
    /*
    Connection not yet established; must not add as and case above;
    else will create multiple connections
    */
    if(!this.canSend) return;

    let command: string = cch.cmd.getCommand();

    if (cch.wrote) { //if command already wrote
      return console.error('Unable to process holder ' + cch + ' already wrote ' );
    }

    try {
      this.client.send(command);
      cch.wrote = true; //wrote command
      //console.debug(command);
    } catch (err) {
      //will not get connection exception here, so not rescheduling
      console.error('Exception while trying to write command ' + cch.cmd, err);
    }
  }

  private connect(): void {
    try {
      this.initClient();
      this.scheduledRetry = false;
    } catch (err) {
      console.error('Exception while trying to init client', err);
      this.clearResourceAndReschedule();
    }
  }

  private clearBacklog(): void {
    //nothing to clear
    if (this.cmdCallbackMap.size === 0) return;

    let keys: number[] = [];
    this.cmdCallbackMap.forEach((v, k) => keys.push(k));
    keys.sort((a, b) => b - a); //revers sort, so pop returns lowest number

    //send command in the same order as we got
    while (keys.length > 0) {
      let tag: any = keys.pop();
      let holder: CommandCallHolder | undefined = this.cmdCallbackMap.get(tag);
      //holder could be null as some one could have removed it.
      if (Util.isNON(holder)) {
        //console.info("Unable to process " + tag);
        continue;
      }
      //canSend will not be true, so using direct connection
      let command: string = holder?.cmd.getCommand() || '';
      this.client.send(command);
      holder!.wrote = true;
      //console.info(command);
    }
  }

  process(): void {
    try {
      this.clearBacklog();
      this.lastActivity = Date.now();
      this.canSend = true; //start accepting new requests
    } catch (err) {
      console.error('Exception while trying to init client', err);
      this.clearResourceAndReschedule();
    }
  }

  msgReceived(connId: number): void {
    if (connId !== this.client.getConnectionId()) return;
    this.lastActivity = Date.now();
  }

  private reSchedule(): void {
    if (this.scheduledRetry) return; //some one else has scheduled the task

    this.scheduledRetry = true;
    setTimeout(() => this.connect(), 20 * 1000);
  }

  hasActiveCommands(): boolean {
    return this.cmdCallbackMap.size > 0;
  }

  disconnectIfNoActiveCmd(): boolean {
    if (!this.hasActiveCommands()) {
      this.client.disconnect();
      this.firstTime = true;
      this.canSend = false;
      console.error(
        'no active subscription, so closed connection, ID - ' +
          this.client.getConnectionId()
      );
      return true;
    }
    return false;
  }

  protected createCommandCallHolder(cmd: Command, callback: ZMWCallback): CommandCallHolder {
    let holder: CommandCallHolder = new CommandCallHolder(cmd, callback);
    this.cmdCallbackMap.set(cmd.getId(), holder);
    //console.debug(cmd.toString() + " map  size " + this.cmdCallbackMap.size);
    return holder;
  }

  protected static uniqueSubService(serviceType: ServiceType, service: string): string {
    return serviceType.getName() + '.' + service;
  }

  protected static getNextId(): number {
    NotificationClient.ID_GEN++;
    return NotificationClient.ID_GEN;
  }

  clearResourceAndReschedule(): void {
    this.canSend = false;
    this.reSchedule();
  }

  processResult(result: Result): void {
    let holder: CommandCallHolder | undefined = this.cmdCallbackMap.get(result.id);

    if (Util.isNON(holder)) {
      console.error(
        'Unknown command id ' + result.id + ' result ' + result
      );
      return;
    }

    let cmd: Command = holder!.cmd;
    let isFailureResp = result.type == RType.FAILURE;
    if (!cmd.getType().isMultiResponse() || isFailureResp) {
      //if single resp command or multi resp cmd with failure, remove reference
      this.cmdCallbackMap.delete(cmd.getId());
    }
    this.onResultCmd(cmd, isFailureResp);
    holder!.callback.onReply(result);

    if(isFailureResp) this.chkAndProcessRetry(holder, result);

    this.disconnectIfNoActiveCmd();
  }

  abstract onConnect(): void;
  abstract onDisconnect(): void;
  abstract onResultCmd(cmd: Command, hadFalureResp: boolean): any;
  abstract chkAndProcessRetry(cmd: CommandCallHolder, result: Result);
}

class ZMWSListener implements Listener {
  private static readonly TAG: string =
    'app.notification.notification_client.ZMWSListener';

  private readonly cmdParser: CommandParser;
  private service: NotificationClient | null;

  constructor(service: NotificationClient, cmdParser: CommandParser) {
    this.service = service;
    this.cmdParser = cmdParser;
  }

  public onConnect(url: string, connId: number): void {
    //console.info("onnected successfully to "+ url +" connId - "+connId);
    this.service?.process();
    this.service?.onConnect();
  }

  public onMessage(connId: number, message: string): void {
    //console.debug(message.trim());
    try {
      this.service?.msgReceived(connId);
      if (message === NOOP) return;

      let result: Result = this.cmdParser.parse(message);
      this.service?.processResult(result);
    } catch (err) {
      let msg = Util.getValue(err.message, 'Exception while processing ' + message);
      console.error(msg, err);
    }
  }

  public onDisconnect(connId: number): void {
    let hasActiveCMDs: boolean | undefined = this.service?.hasActiveCommands();
    if (hasActiveCMDs) {
      console.error('disconnected when activeCMDs exist; ID - ' + connId);
      this.service?.clearResourceAndReschedule();
      this.service?.onDisconnect();
    } else {
      this.service?.stop();
    }
    this.service = null;
  }

  public onError(connId: number, err: Error): void {
    /*
         exception doesn't mean there is some connection exception
         */
    console.error('Unknown exception', err);
  }
}

export interface CommandParser {
  parse(msg: string): Result;
}

export class CommandCallHolder {
  readonly cmd: Command;
  readonly callback: ZMWCallback;
  wrote: boolean;

  constructor(cmd: Command, callback: ZMWCallback) {
    this.cmd = cmd;
    this.callback = callback;
    this.wrote = false;
  }

  public toString(): string {
    return (
      'CommandCallHolder{' + 'cmd=' + this.cmd + ', wrote=' + this.wrote + '}'
    );
  }
}
