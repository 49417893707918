import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Constants } from '../constants/constants';
import { DomainProfile, UserInfo } from '../model/common-types';
import { ResolvablePromise } from '../settings/settings-transition/resolvable-promis';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  //userInfoSub = new Subject<any>();
  userInfo: UserInfo = null;
  domainProfile:DomainProfile = null;
  _acccountLocked:boolean  = false;
  _lockoutDuraion:number = 0;

  constructor(http: HttpClient, private router: Router) {
    super(http, ['/auth']);
    //  this.userInfoSub.subscribe(res => {this.userInfo = res;});
  }

  login(loginForm: any): Promise<UserInfo> {
    return super.login(loginForm);
  }

  logout(router: Router): void {
    // UserInfo will be cleared via clearCache method
    super._logout().then((_) => this.onLogoutComplete(router));
  }

  private onLogoutComplete(router: Router) {
    router.navigate(['login']);
    /*
    if (location && location.hostname === 'app.dolphinsignage.com') {
      this.setWindowLocation(
        Constants.GET_OAUTH_LOGOUT_URL + '?provider=dolphin'
      );
    }  else if (location && location.hostname === 'app.dolphinsignage.in') {
      this.setWindowLocation(Constants.GET_OAUTH_LOGOUT_URL + "?provider=dolphin_india");
    }  else {
      router.navigate(['login']);
    }
    */
  }

  sessionExpired(): void {
    this.processSessionExpired();
    this.router.navigate(['login']);
  }

  lockAccount(locked:boolean){
    this._acccountLocked = locked; 
  }

  isAccountLocked():boolean{
    return this._acccountLocked;
  }

  setLockoutDuration(duration:number){
    this._lockoutDuraion = duration;
  }

  getLockoutDuration():number{
    return this._lockoutDuraion ;
  }


  isLoggedIn(): boolean {
    return super.isLoggedIn();
  }

  private userLoggPro: ResolvablePromise<UserInfo, Error> = null;

  isUserLoggedIn(): Promise<UserInfo> {
    if (this.userLoggPro != null) return this.userLoggPro.getPromise();

    this.userLoggPro = new ResolvablePromise();

    // .get(Constants.GET_IS_USER_LOGGEDIN_URL, { responseType: 'text' })
    // .toPromise()
    this.getUserInfo()
      .then((uInfo) => {
        this.userLoggPro.resolve(uInfo);
        this.userLoggPro = null;
      })
      .catch((_) => {
        this.userLoggPro = null;
        this.onNotLoggedIn();
      });

    return this.userLoggPro.getPromise();
  }

  private onNotLoggedIn() {
    localStorage.removeItem('logged_in');

    /* if (location && location.hostname === 'app.dolphinsignage.com') {
      this.oauthAuthorize('dolphin');
    } else if(location && location.hostname === "app.dolphinsignage.in") {
      this.oauthAuthorize("dolphin_india");
    }*/
  }

  private setWindowLocation(url: string) {
    url = 'http://' + window.location.host + url;
    window.location.href = url;
  }

  public oauthLogin(data):Promise<UserInfo> {
    return super.oauthLogin(data);
    //return this.http.post<UserInfo>(Constants.GET_OAUTH_LOGIN_URL, data, JSON_HEADER).toPromise();
  }

  private oauthAuthorize(provider: string) {
    this.setWindowLocation(
      Constants.GET_OAUTH_AUTHORIZE_URL + '?provider=' + provider
    );
  }

  getUserName(): string {
    return this.userInfo ? this.userInfo.user_name : null;
  }
  
  getOrgName(): string {
    return this.userInfo ? this.userInfo.org_name : null;
  }

  getUserRole() {
    return this.userInfo && this.userInfo.urole ? this.userInfo.urole : null;
  }

  getLanguage() {
    return this.userInfo && this.userInfo.language ? this.userInfo.language : null;
  }

  isMediaDeleteAllowed(): boolean {
    return (
      this.userInfo && this.userInfo.urole && this.userInfo.urole == 'ADMIN'
    );
  }

  isMediaScheduleAllowed(): boolean {
    return (
      this.userInfo && this.userInfo.urole && this.userInfo.urole == 'ADMIN'
    );
  }

  isMediaUploadAllowed(): boolean {
    return this.userInfo && this.userInfo.urole == 'BROADCAST_MANAGER'
      ? this.userInfo.canupload
      : true;
  }

  isResetPassword(): boolean {
    return this.userInfo && this.userInfo.reset_pwd && this.userInfo.reset_pwd == true;
  
  }
  isBetaUser(): boolean {
    return this.userInfo && this.userInfo.beta && this.userInfo.beta == true;
  }

  isMFAEnabled(): boolean {
    return this.userInfo && this.userInfo.mfa && this.userInfo.mfa == true;
  }

  setMFAEnabled(value:boolean){
    if( this.userInfo) this.userInfo.mfa = value;
  }

  isOnPremise(): boolean {
    return this.userInfo && this.userInfo.op;
  }

  // isRoleDisplayViewer(): boolean {
  //   return this.userInfo && this.userInfo.urole && this.userInfo.urole == 'DISPLAY_VIEWER';
  // }

  isRoleDisplayManager(): boolean {
    return (
      this.userInfo &&
      this.userInfo.urole &&
      this.userInfo.urole == 'DISPLAY_MANAGER'
    );
  }

  setUserInfo(uInfo: UserInfo) {
    this.userInfo = uInfo;
  }

  getUserInfo(): Promise<UserInfo> {
    if (this.userInfo && this.userInfo.user_name != null) return Promise.resolve(this.userInfo);

    return this.http
      .get<UserInfo>(Constants.GET_LOGIN_USER_INFO_URL)
      .pipe(tap((res) => (this.userInfo = res)))
      .toPromise();
  }

  getDomainProfile(): Promise<DomainProfile> {
    if (this.domainProfile) return Promise.resolve(this.domainProfile);

    return this.http
      .get<DomainProfile>(Constants.GET_DOMAIN_PROFILE_URL)
      .pipe(tap((res) => (this.domainProfile = res)))
      .toPromise();
  }

  isProfileSupported(key:string){
    return this.domainProfile && key in this.domainProfile && this.domainProfile[key] ;
  }

  protected onCUDEvent(verb: string): void {}

  // Clear only when free resouce is called
  protected onCUDEventSubStatus(sub: boolean): void {}

  freeResource(): void {
    this.userInfo = null;
    this.domainProfile = null;
  }
}
